import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import Menu from '@material-ui/core/Menu';
import { withStyles } from '@material-ui/core/styles';
import Icon from '../Icon/Icon';
import { Header4, Text1, Text2, Text4 } from '../../styles';

const StyledMenu = withStyles({
  paper: {
    border: '1px solid var(--deal-column-bg)',
    padding: '0px 8px',
    background: 'var(--deal-column-bg)',
    boxShadow: '0px 6px 25px var(--modal-shadow)',
    borderRadius: '8px',
    width: '199px',
    marginTop: '20px',
    marginLeft: '-10px'
  }
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center'
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center'
    }}
    {...props}
  />
));
const StyledMenuTwo = withStyles({
  paper: {
    border: '1px solid var(--gray-border-color)',
    padding: '0px 6px',
    background: 'var(--white-color)',
    boxShadow: '0px 3px 10px var(--menu-modal)',
    borderRadius: '8px',
    width: '184px',
    marginLeft: '-8px'
  }
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center'
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center'
    }}
    {...props}
  />
));

// const NavIcon = styled(Icon).attrs((props) => ({ icon: props.icon, // or whatever you want here }))``;

const NavIcon = styled(Icon).attrs((props) => ({
  color: 'var(--dark-color)',
  fontSize: '18'
}))``;

const MenuItemLink = styled(NavLink)`
  &.active_menu {
    background: var(--gainsBoro-color);
    border-radius: 6px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: var(--dark-color);
  }
  text-decoration: none;
  display: flex;
  align-items: center;
  line-height: 1;
  padding: 7px 10px;
  border-radius: 6px;
  cursor: pointer;
`;
const MainNav = styled.div`
  width: 200px;
  height: 100%;
`;
const MainNavWrapper = styled.div`
  background: var(--cultured-color);
  padding: 0 8px 20px 8px;
  width: 200px;
  height: 100%;
  border-right: 1px solid var(--Platinum-color);
`;

const NavList = styled.div`
  margin: 6px 0 10px 0;
  height: calc(100% - 100px);
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow-y: scroll;
  width: 200px;
  &::-webkit-scrollbar {
    display: none;
  }
`;
const MoreIcon = styled.div`
  color: var(--gray-color);
  font-size: 18px;
  line-height: 18px;
`;
const ListView = styled.div``;
const Ul = styled.div`
  list-style: none;
  padding: 0;
  margin: 6px 0 6px 0;
`;
const Li = styled.li`
  margin: 0px 8px 8px 8px;
  border-radius: 6px;
  :hover {
    background: var(--gainsBoro-color);
  }
`;
const LiMore = styled.li`
  margin: 5px 8px 8px 8px;
  border-radius: 6px;

  i {
    color: var(--gray-more-icon);
  }
  span {
    color: var(--gray-more-icon);
  }
  :hover {
    i {
      color: var(--dark-color);
    }
    span {
      color: var(--dark-color);
    }
  }
`;
const Alist = styled.div`
  display: flex;
  align-items: center;
  line-height: 1;
  padding: 7px 10px;
  text-decoration: none;
  border-radius: 6px;
  color: var(--gray-color);
  cursor: pointer;
  &:hover {
    background: var(--gainsBoro-color);
  }
  &:active {
    background: var(--gainsBoro-color);
  }
`;
const AlistMore = styled.div`
  display: flex;
  align-items: center;
  line-height: 1;
  padding: 7px 10px;
  text-decoration: none;
  border-radius: 6px;
  color: var(--gray-color);
  cursor: pointer;
`;
const SpanList = styled.span`
  ${Text4};
  color: var(--dark-color);
  margin-left: 16px;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
`;
const NumbList = styled.span`
  ${Text2};
  color: var(--regent-gray-color);
  margin-left: auto;
`;
const SpanListtwo = styled.span`
  ${Text4};
  color: var(--gray-color);
  margin-left: 16px;
`;
const NavBottom = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  padding: 10px 8px 0 8px;
  background: var(--cultured-color);
  width: 200px;
  border-right: 1px solid var(--Platinum-color);
`;
const Menulist = styled.span``;
const UlTwo = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;
const LiTwo = styled.li`
  margin: 0 0 2px 0;
`;
const AlistTwo = styled.a`
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  color: var(--dark-color);
  padding: 7px 10px;
  cursor: pointer;
  border-radius: 6px;
  &:hover {
    background: var(--popup-color-hover);
  }
  &:active {
    background: var(--popup-color-hover);
  }
`;
const SpanListMenu = styled.span`
  ${Text1};
  color: var(--dark-color);
`;
const MainContain = styled.div`
  background: var(--white-color);
  border: 1px solid var(--Platinum-color);
  box-shadow: 0px 6px 25px var(--modal-shadow);
  border-radius: 8px;
  width: 323px;
  height: auto;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  outline: none;
`;
const FirstRow = styled.div`
  padding: 14px 12px 13px 12px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--Platinum-color);
`;
const RightView = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;
const HeadTitle = styled.h3`
  ${Header4};
  color: var(--nav-dark-color);
  margin: 0;
  margin-left: 12px;
`;
const SeconView = styled.div`
  padding: 7px 10px 8px 10px;
`;
const LinkTag = styled.a`
  text-decoration: none;
  display: block;
`;
const SecondRow = styled.div`
  display: flex;
  padding: 10px 12px;
  cursor: pointer;
  border-radius: 5px;
  :hover {
    background-color: var(--popup-color-hover);
  }
`;
const TextView = styled.div`
  margin-left: 12px;
`;
const HeadCaption = styled.h3`
  ${Text1};
  color: var(--nav-dark-color);
  margin: 0;
  margin-bottom: 2px;
`;
const CaptionText = styled.span`
  ${Text2};
  color: var(--gray-color);
`;
const IconView = styled.div`
  cursor: pointer;
`;

const SpanPlanExpired = styled.span`
  ${Text1};
  color: var(--dark-color);
`;
const SpanPlan = styled.span`
  ${Text1};
  color: var(--Red-salsa-color);
`;
const PlanLi = styled.li`
  background-color: var(--gainsBoro-color);
  text-align: center;
  margin: 0 -8px;
  padding: 11px;
  list-style: none;
`;
const IconPluse = styled.div`
  margin-left: auto;
  width: 16px;
  height: 16px;
`;

export {
  MainNav,
  MainNavWrapper,
  NavList,
  Ul,
  Li,
  Alist,
  SpanList,
  ListView,
  NumbList,
  SpanListtwo,
  NavBottom,
  StyledMenu,
  StyledMenuTwo,
  MenuItemLink,
  Menulist,
  UlTwo,
  LiTwo,
  AlistTwo,
  SpanListMenu,
  MainContain,
  FirstRow,
  RightView,
  HeadTitle,
  SeconView,
  SecondRow,
  TextView,
  HeadCaption,
  CaptionText,
  IconView,
  NavIcon,
  MoreIcon,
  LinkTag,
  SpanPlanExpired,
  PlanLi,
  LiMore,
  AlistMore,
  SpanPlan,
  IconPluse
};
