import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiInputBase-root': {
      fontSize: 14,
      border: 'none',
      '& .MuiButtonBase-root': {
        position: 'relative'
      },
      '& .MuiInputBase-input': {
        paddingLeft: 0,
        '& #picker': {
          borderLeft: '1px solid var(--gray-border-color)'
        }
      },

      '&.MuiInputBase-input .MuiInput-input': {
        paddingLeft: 0
      },
      '&.MuiInput-underline:before,&.MuiInput-underline:after': {
        display: 'none'
      },
      '&.MuiInput-underline .MuiInputBase-input': {
        border: '1px solid var(--gray-border-color)',
        borderRadius: 4,
        padding: '6px 12px',
        height: 'auto',
        '&:focus': {
          border: '2px solid var(--primary-color)',
          margin: '-1px'
        }
      },
      '&.MuiInput-underline .MuiIconButton-root': {
        padding: 11
      },
      '&.MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-formControl.MuiInput-formControl': {
        paddingTop: 0
      }
    }
  }
}));
const TimePicker = ({ label, defaultValue, value, ...props }) => {
  const classes = useStyles();
  return (
    <TextField
      label={label}
      type='time'
      defaultValue={defaultValue}
      InputLabelProps={{
        shrink: true
      }}
      inputProps={{
        step: 300 // 5 min
      }}
      classes={{
        root: classes.root,
        checked: classes.checked
      }}
      value={value}
      {...props}
    />
  );
};

export default TimePicker;

TimePicker.propTypes = {
  label: PropTypes.string,
  defaultValue: PropTypes.instanceOf(Date)
};
