import React, { Component } from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import { Header4, Text1 } from '../../styles';

const TextInput = styled.input`
  padding: 11px 20px;
  border: 1px solid var(--gray-border-color);
  width: 100%;
  outline: 0;
  background: var(--white-color);
  border-radius: 4px;
  ${Header4}
  transition: 0.1s all ease;
  &::placeholder {
    color: var(--gray-icon-color);
    font-weight: 500;
  }
  &:focus {
    border: 2px solid var(--primary-color);
    margin: -1px;
  }
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  [type='number'] {
    -moz-appearance: textfield;
  }
`;
const TextInputSmall = styled.input`
  padding: 7px 12px;
  border: 1px solid var(--text-input-small);
  width: 100%;
  outline: 0;
  background: var(--white-color);
  border-radius: 4px;
  ${Text1}
  transition: 0.1s all ease;
  letter-spacing: 0.5px;
  &::placeholder {
    color: var(--gray-icon-color);
    font-weight: 500;
  }
  &:focus {
    border: 2px solid var(--primary-color);
    margin: -1px;
  }
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  [type='number'] {
    -moz-appearance: textfield;
  }
`;
const InputLabel = styled.label`
  ${Text1}
  text-transform: capitalize;
  color: var(--dark-color);
  margin-bottom: 4px;
  display: block;
`;
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  ${(props) =>
    css`
      ${props.marginBottom}
    `}
`;
class Input extends Component {
  render() {
    const {
      type,
      disabled,
      placeholder,
      autoFocus,
      value,
      onChange,
      onKeyDown,
      min,
      max,
      title,
      isPrimary = false,
      isPrimarySmall = false,
      isPrimaryLable = false,
      checked = false,
      marginBottom
    } = this.props;
    return (
      <>
        {isPrimary && (
          <TextInput
            min={min}
            max={max}
            {...this.props}
            type={type}
            onChange={onChange}
            disabled={disabled}
            placeholder={placeholder}
            autoFocus={autoFocus}
            value={value}
            onKeyDown={onKeyDown}
          />
        )}
        {isPrimarySmall && (
          <Wrapper marginBottom={marginBottom}>
            {title && <InputLabel>{title}</InputLabel>}
            <TextInputSmall
              min={min}
              max={max}
              {...this.props}
              type={type}
              onChange={onChange}
              disabled={disabled}
              placeholder={placeholder}
              autoFocus={autoFocus}
              value={value}
              checked={checked}
              onKeyDown={onKeyDown}
            />
          </Wrapper>
        )}
        {isPrimaryLable && (
          <>
            <InputLabel>{title}</InputLabel>
            <TextInput
              min={min}
              max={max}
              {...this.props}
              type={type}
              onChange={onChange}
              disabled={disabled}
              placeholder={placeholder}
              autoFocus={autoFocus}
              value={value}
              onKeyDown={onKeyDown}
            />
          </>
        )}
      </>
    );
  }
}

export default Input;
Input.propTypes = {
  isPrimary: PropTypes.bool,
  // title: PropTypes.string.isRequired,
  type: PropTypes.oneOf([
    'color',
    'checkbox',
    'button',
    'email',
    'file',
    'number',
    'password',
    'radio',
    'submit',
    'text'
  ])
};
