import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setCompanyItem } from '../../actions/companyActions';
import { setCompanySubscriptions } from '../../actions/subscriptionActions';
import Avtar from '../../components/Avtar/Avtar';
import Icon from '../../components/Icon/Icon';
import { SUBSCRIPTION_PLAN_STATUS } from '../../global/constants';
import { getCompanyItem } from '../../services/companyServices';
import { getSubscriptionsByCompany } from '../../services/subscriptionServices';
import {
  AvtarWrapper,
  DaysLabel,
  Label,
  Li,
  TopBar,
  Ul,
  UserBlog,
  UserDetails,
  UserLocation,
  UserName,
  AvtarBlog,
  Admin,
  NameTitle,
  WorkPlaceBlog
} from '../Users/styles';
import {
  MainContact,
  RowList,
  TitleView,
  UserWrapper,
  WorkplaceWrapper,
  Phone,
  Email,
  WrapperWork,
  More,
  Title,
  UsersWrapper,
  SubscriptionBlog,
  SubTitle,
  DetailWrapper,
  Amount,
  PlanName,
  Plan,
  Status,
  Blog,
  BlogDetails,
  From,
  To,
  End,
  List
} from './styles';
const CompanyDetailModal = ({ onClose, company, openAddSubscriptionModal, onSelectItem }) => {
  const subscriptionSelector = useSelector((state) => state.subscription);
  const { companySubscriptions } = subscriptionSelector;
  const companySelector = useSelector((state) => state.company);
  const { companyItem } = companySelector;
  const dispatch = useDispatch();
  const [isShowMoreUser, setIsShowMoreUser] = useState(false);
  const loadData = useCallback(() => {
    dispatch(getCompanyItem(company?.id));
    dispatch(getSubscriptionsByCompany(company?.id));
  }, [company?.id, dispatch]);

  const userCount = useMemo(() => {
    return companyItem?.users?.length;
  }, [companyItem?.users?.length]);

  useEffect(() => {
    loadData();
    return () => {
      dispatch(setCompanyItem(null));
      dispatch(setCompanySubscriptions(null));
    };
  }, [dispatch, loadData]);

  return (
    <MainContact>
      <TopBar>
        <TitleView>
          <Icon
            name='icon-close'
            color='var(--gray-icon-color)'
            fontSize='20'
            onClick={() => {
              onClose();
            }}
            title={'Close'}
          />
        </TitleView>
        <UserWrapper>
          <AvtarWrapper>
            <Avtar imgSrc={companyItem?.logoUrl} name={companyItem?.name} size={60} />
          </AvtarWrapper>
          <UserBlog>
            <UserDetails>
              <UserName>{companyItem?.name}</UserName>
              <UserLocation>location</UserLocation>
            </UserDetails>
          </UserBlog>
        </UserWrapper>
        <Ul>
          <Li>
            <Label>MMR :</Label>
            <DaysLabel>35 USD</DaysLabel>
          </Li>
          <Li>
            <Label>LTV :</Label>
            <DaysLabel>350 USD</DaysLabel>
          </Li>
        </Ul>
      </TopBar>
      <WorkplaceWrapper>
        <UsersWrapper>
          <Title>Users</Title>
          {userCount > 0 &&
            companyItem?.users?.slice(0, isShowMoreUser ? userCount : 3)?.map((user, index) => {
              return (
                <RowList key={`${user?.id}_${index}`}>
                  <AvtarBlog>
                    <Avtar imgSrc={user?.profile_photo} name={user?.name} size={42} />
                  </AvtarBlog>
                  <WrapperWork>
                    <WorkPlaceBlog>
                      <NameTitle>{user?.name}</NameTitle>
                      {user?.isAdmin && <Admin>Admin</Admin>}
                    </WorkPlaceBlog>
                    <WorkPlaceBlog>
                      {user?.email && <Email>{user?.email}</Email>}
                      {user?.phone && <Phone>{user?.phone}</Phone>}
                    </WorkPlaceBlog>
                  </WrapperWork>
                </RowList>
              );
            })}
          {userCount > 3 && (
            <More
              onClick={() => {
                setIsShowMoreUser(!isShowMoreUser);
              }}>
              {isShowMoreUser ? 'Less' : `${userCount - 3} More`}
            </More>
          )}
        </UsersWrapper>
        <div>
          <SubscriptionBlog>
            <SubTitle>Subscription</SubTitle>
            <Icon
              name='icon-add-plus'
              color='var(--gray-icon-color)'
              fontSize='24'
              onClick={() => {
                openAddSubscriptionModal();
              }}
            />
          </SubscriptionBlog>
          {companySubscriptions?.map((item, index) => {
            return (
              <RowList
                key={`${item?.id}_${index}`}
                onClick={() => {
                  onSelectItem(item);
                }}>
                <List>
                  <Blog>
                    <DetailWrapper>
                      <PlanName>{item?.planName}</PlanName>
                      <Plan>{item?.plan}</Plan>
                      <Status status={SUBSCRIPTION_PLAN_STATUS[item?.status]}>{item?.status}</Status>
                    </DetailWrapper>
                    <Amount>${item?.amount}</Amount>
                  </Blog>
                  <BlogDetails>
                    <From>From {moment(item?.start).format('Do MMM, YYYY')}</From>
                    <To>- to -</To>
                    <End>{moment(item?.end).format('Do MMM, YYYY')}</End>
                  </BlogDetails>
                </List>
              </RowList>
            );
          })}
        </div>
      </WorkplaceWrapper>
    </MainContact>
  );
};

export default CompanyDetailModal;
