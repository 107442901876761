import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Avtar from '../Avtar/Avtar';
import TitleSearchBar from '../TitleSearchBar/TitleSearchBar';
import { ROUTE } from '../../global/routeConst';
import { logout } from '../../services/authServices';
import { StyledMenu, UlTwo, LiTwo, AlistTwo, SpanListMenu } from '../Navbar/styles';
import {
  NavbarTopNavigation,
  ArrowNavigation,
  BackButton,
  ForwordButton,
  ManeWrapper,
  NavbarLeft,
  NavbarRightWrapper,
  NavbarRight
} from './styles';

const TitleBar = (props) => {
  const authSelector = useSelector((state) => state.auth);
  const { currentUser } = authSelector;

  let history = useHistory();
  const handleClickOnLeftArrow = useCallback(() => {
    history.goBack();
  }, [history]);
  const handleClickOnRightArrow = useCallback(() => {
    history.goForward();
  }, [history]);

  const [anchorEl, setAnchorEl] = useState(null);
  const closeProfileMenu = (e) => {
    toggleProfileMenu(false);
    setAnchorEl(null);
  };
  const openProfileMenu = (e) => {
    toggleProfileMenu(true);
    setAnchorEl(e.currentTarget);
  };
  const [isOpenProfileMenu, toggleProfileMenu] = useState(false);

  const onClickLogout = () => {
    dispatch(logout());
    history.push(ROUTE.LOGIN);
  };
  const dispatch = useDispatch();

  return (
    <>
      <ManeWrapper>
        <NavbarLeft>
          <NavbarTopNavigation>
            <ArrowNavigation>
              <BackButton
                iconName='icon-left-arrow'
                color='var(--gray-more-icon)'
                disabled={history.length === 2}
                onClick={handleClickOnLeftArrow}
              />
              <ForwordButton
                iconName='icon-right-arrow'
                color='var(--gray-more-icon)'
                disabled={history.length === 2}
                onClick={handleClickOnRightArrow}
              />
            </ArrowNavigation>
          </NavbarTopNavigation>
        </NavbarLeft>
        <TitleSearchBar />
        <NavbarRightWrapper>
          <NavbarRight onClick={openProfileMenu}>
            <Avtar imgSrc={currentUser?.profile_photo} name={currentUser?.given_name} size={24} />
          </NavbarRight>
        </NavbarRightWrapper>
      </ManeWrapper>

      <StyledMenu
        id='customized-user'
        anchorEl={anchorEl}
        keepMounted
        open={isOpenProfileMenu}
        onClose={closeProfileMenu}>
        <UlTwo>
          <LiTwo>
            <AlistTwo onClick={onClickLogout}>
              <SpanListMenu>Logout</SpanListMenu>
            </AlistTwo>
          </LiTwo>
        </UlTwo>
      </StyledMenu>
    </>
  );
};

export default TitleBar;
