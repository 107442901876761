import React from 'react';
import PropTypes from 'prop-types';
import { Menu as MaterialMenu } from '@material-ui/core';

const Menu = ({ position, positionVertical, width, marginTop, ...props }) => {
  return (
    <>
      <MaterialMenu
        keepMounted
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: positionVertical,
          horizontal: position
        }}
        transformOrigin={{
          vertical: positionVertical,
          horizontal: position
        }}
        PaperProps={{
          style: {
            width: width,
            marginTop: marginTop,
            borderRadius: 8,
            boxShadow: '0px 3px 10px var(--menu-modal)',
            border: '1px solid var(--gray-border-color)'
          }
        }}
        {...props}
      />
    </>
  );
};

export default Menu;

Menu.propTypes = {
  open: PropTypes.bool,
  position: PropTypes.oneOf(['right', 'left', 'center']),
  positionVertical: PropTypes.oneOf(['top', 'bottom', 'center']),
  width: PropTypes.number,
  marginTop: PropTypes.number
};

Menu.defaultProps = {
  open: true,
  position: 'right',
  positionVertical: 'bottom'
};
