import { createAction } from '@reduxjs/toolkit';
import * as Actions from './types';

/**
 * @desc Set User Loader
 */
export const setUserLoader = createAction(Actions.SET_USER_LOADER);
/**
 * @desc Set Users
 */
export const setUsers = createAction(Actions.SET_USERS);
