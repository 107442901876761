import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { isDev } from '../helpers/common';
import { REACT_APP_SENTRY_KEY } from '../global/environment';

/**
 * @desc init log system
 */
export const initLogService = () => {
  try {
    if (isDev()) return;

    if (!REACT_APP_SENTRY_KEY) {
      console.log('REACT_APP_SENTRY_KEY is not found');
      return;
    }

    Sentry.init({
      dsn: REACT_APP_SENTRY_KEY,
      integrations: [new Integrations.BrowserTracing()],

      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 1.0
    });
  } catch (e) {
    console.error(e);
  }
};

/**
 * @desc set userid and email for error log
 */
export const setLogUser = (user) => {
  try {
    if (isDev()) return;

    if (user) {
      Sentry.configureScope(function (scope) {
        scope.setUser({ id: user.id, email: user.email });
      });
    }
  } catch (e) {
    console.log(e);
  }
};
