import { ROUTE } from '../global/routeConst';
import Dashboard from '../pages/Dashboard';
import Subscriptions from '../pages/Subscriptions';
import Users from '../pages/Users';
import Workspaces from '../pages/Workspaces';

/**
 * Routes Array
 * Same properties should match for all attributes
 */
const routes = [
  {
    path: ROUTE.HOME,
    exact: true,
    component: Dashboard,
    private: true
  },
  {
    path: ROUTE.USERS,
    exact: true,
    component: Users,
    private: true
  },
  {
    path: ROUTE.WORKSPACES,
    exact: true,
    component: Workspaces,
    private: true
  },
  {
    path: ROUTE.SUBSCRIPTIONS,
    exact: true,
    component: Subscriptions,
    private: true
  }
];

export default routes;
