import React, { useCallback, useState } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import TitleBar from '../../components/TitleBar';
import Navbar from '../../components/Navbar';
import homeRoutes from '../../routes/home-routes';
import PageNotFound from '../PageNotFound';
import { ROUTE } from '../../global/routeConst';
import PrivateRoute from './../../components/PrivateRoutes';
import { ManeWrapper, RightWrapper, ManeBlock, Link } from './styles';

export default function Home(props) {
  const [navbarNavigation, setNavbarNavigation] = useState(false);
  const [sideBarValue, setSideBarValue] = useState(1);
  const routeComponents = homeRoutes.map((r, i) => {
    if (r.private) {
      return <PrivateRoute exact key={i} {...r} />;
    }
    return <Route exact key={i} {...r} />;
  });

  const onChangeSideBar = useCallback((value) => {
    setSideBarValue(value);
  }, []);

  const setNavBar = (value) => {
    setSideBarValue(value);
  };

  return (
    <>
      <TitleBar setNavBar={setNavBar} />
      <ManeWrapper>
        <ManeBlock className={sideBarValue === 1 && navbarNavigation && 'sidebar'}>
          <Navbar
            navbarNavigation={navbarNavigation}
            sideBarValue={sideBarValue}
            onChangeSideBar={onChangeSideBar}
            {...props}
          />
          <Link
            className={sideBarValue === 1 && 'resizeDriver'}
            onClick={() => setNavbarNavigation(!navbarNavigation)}
          />
        </ManeBlock>
        <RightWrapper className='resizeWrapper'>
          <Switch>
            <Redirect exact path='/' to={ROUTE.HOME} />
            {routeComponents}
            <Route component={PageNotFound} />
          </Switch>
        </RightWrapper>
      </ManeWrapper>
    </>
  );
}
