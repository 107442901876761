import { Modal } from '@material-ui/core';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Table from '../../components/Table/Table';
import { DATE_FORMAT } from '../../global/constants';
import UserPreferenceSingleton from '../../helpers/userPreferenceSingleton';
import { Menu, MenuItem } from '../../components/Menu';

import { getSubscriptions } from '../../services/subscriptionServices';
import AddSubscriptionModal from './AddSubscriptionModal';
const SORTED_FEILDS = 'sortedFeilds';
const TABNAME = 'subscriptions';
const Subscriptions = () => {
  const [isOpenAddSubscriptionModal, setIsOpenAddSubscriptionModal] = useState(false);

  const subscriptionSelector = useSelector((state) => state.subscription);
  const { list: subscriptions } = subscriptionSelector;
  const dispatch = useDispatch();
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [isMoreMenuOpen, setIsMoreMenuOpen] = useState(false);
  const [itemId, setItemId] = useState();

  const loadData = useCallback(() => {
    dispatch(getSubscriptions());
  }, [dispatch]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const tableColumns = useMemo(() => {
    return [
      { Header: 'Plan Name', accessor: 'planName' },
      { Header: 'Plan', accessor: 'plan' },
      { Header: 'Status', accessor: 'status' },
      { Header: 'Start Date', accessor: 'start' },
      { Header: 'End Date', accessor: 'end' }
    ];
  }, []);

  const sortedFeild = useMemo(() => {
    const sortedFeild = UserPreferenceSingleton.getInstance().getSettingValue(TABNAME, SORTED_FEILDS);
    return sortedFeild;
  }, []);

  const initialStateOfTable = useMemo(() => {
    if (sortedFeild?.fieldId && sortedFeild?.desc !== undefined) {
      const initialState = {
        sortBy: [
          {
            id: sortedFeild.fieldId,
            desc: sortedFeild.desc
          }
        ]
      };
      return initialState;
    }
    return undefined;
  }, [sortedFeild?.desc, sortedFeild?.fieldId]);

  const tableData = useMemo(() => {
    const columns = tableColumns;
    const data = subscriptions?.map((subscription) => {
      const rowData = {
        id: subscription.id,
        planName: subscription?.planName,
        plan: subscription?.plan,
        status: subscription?.status,
        start: moment(subscription?.start).format(DATE_FORMAT),
        end: moment(subscription?.end).format(DATE_FORMAT)
      };
      return rowData;
    });
    return {
      columns,
      data
    };
  }, [subscriptions, tableColumns]);

  const openMoreMenu = useCallback((e, id) => {
    e.stopPropagation();
    setIsMoreMenuOpen(true);
    setMenuAnchorEl(e.currentTarget);
    setItemId(id);
  }, []);

  const onCloseMoreMenu = useCallback(() => {
    setIsMoreMenuOpen(false);
    setMenuAnchorEl(null);
    setItemId();
  }, []);

  const onOpenAddSubscriptionModal = useCallback(() => {
    setIsOpenAddSubscriptionModal(true);
  }, []);

  const onCloseAddSubscriptionModal = useCallback(() => {
    onCloseMoreMenu();
    setIsOpenAddSubscriptionModal(false);
  }, [onCloseMoreMenu]);

  return (
    <>
      <Table
        columns={tableData?.columns || []}
        data={tableData?.data || []}
        tabName={TABNAME}
        initialState={initialStateOfTable}
        openMoreMenu={openMoreMenu}></Table>
      <Modal
        open={isOpenAddSubscriptionModal}
        onClose={onCloseAddSubscriptionModal}
        aria-labelledby='simple-modal-title'
        aria-describedby='simple-modal-description'
        BackdropProps={{ invisible: true }}>
        <AddSubscriptionModal onClose={onCloseAddSubscriptionModal} itemId={itemId} onSave={loadData} />
      </Modal>
      <Menu
        id='more-menu'
        anchorEl={menuAnchorEl}
        keepMounted
        open={isMoreMenuOpen}
        onClose={onCloseMoreMenu}
        getContentAnchorEl={null}
        position='right'
        positionVertical='top'
        width={140}
        marginTop={25}>
        <MenuItem
          iconName='icon-edit-pen'
          fontSize='16'
          color='var(--gray-icon-color)'
          text='Edit'
          onClick={onOpenAddSubscriptionModal}
        />
      </Menu>
    </>
  );
};

export default Subscriptions;
