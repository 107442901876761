import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setUserCompanies } from '../../actions/companyActions';
import Avtar from '../../components/Avtar/Avtar';
import Icon from '../../components/Icon/Icon';
import { getGravatarURL } from '../../helpers/common';
import { getCompaniesByUser } from '../../services/companyServices';
import {
  MainContact,
  RowItem,
  RowList,
  TitleView,
  UserWrapper,
  UserBlog,
  UserDetails,
  UserDetailsWrapper,
  TopBar,
  AvtarWrapper,
  UserName,
  UserLocation,
  UserDetailsPhone,
  UserPhoneDetails,
  Ul,
  Li,
  Label,
  DaysLabel,
  UserDetailsEmail,
  WorkplaceWrapper,
  Title,
  WorkUser,
  NameTitle,
  Admin,
  Plan,
  Users,
  WorkPlaceBlog,
  AvtarBlog
} from './styles';

const UserDetailModal = ({ onClose, user, openCompanyDetail }) => {
  const companySelector = useSelector((state) => state.company);
  const { companies } = companySelector;
  const gravatar = getGravatarURL(user?.email);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getCompaniesByUser(user?.id));
    return () => {
      dispatch(setUserCompanies(null));
    };
  }, [dispatch, user?.id]);

  return (
    <MainContact>
      <TopBar>
        <TitleView>
          <Icon
            name='icon-close'
            color='var(--gray-icon-color)'
            fontSize='20'
            onClick={() => {
              onClose();
            }}
            title={'Close'}
          />
        </TitleView>
        <UserWrapper>
          <AvtarWrapper>
            <Avtar imgSrc={gravatar} size={58} />
          </AvtarWrapper>
          <UserBlog>
            <UserDetails>
              <UserName>{user?.name}</UserName>
              <UserLocation>location</UserLocation>
            </UserDetails>
            <UserDetailsWrapper>
              {user?.phone && (
                <UserPhoneDetails>
                  <Icon name='icon-phone' fontSize='16' color='var(--gray-icon-color)' />
                  <UserDetailsPhone>{user?.phone}</UserDetailsPhone>
                </UserPhoneDetails>
              )}
              {user?.email && (
                <UserDetailsWrapper>
                  <Icon name='icon-email' fontSize='16' color='var(--gray-icon-color)' />
                  <UserDetailsEmail title={user?.email}>{user?.email}</UserDetailsEmail>
                </UserDetailsWrapper>
              )}
            </UserDetailsWrapper>
          </UserBlog>
        </UserWrapper>
        <Ul>
          <Li>
            <Label>Last Seen :</Label>
            <DaysLabel>3 dyas ago</DaysLabel>
          </Li>
          <Li>
            <Label>MMR :</Label>
            <DaysLabel>35 USD</DaysLabel>
          </Li>
          <Li>
            <Label>Session :</Label>
            <DaysLabel>33</DaysLabel>
          </Li>
          <Li>
            <Label>LTV :</Label>
            <DaysLabel>350 USD</DaysLabel>
          </Li>
        </Ul>
      </TopBar>
      <WorkplaceWrapper>
        <Title>Workplace</Title>
        {companies?.map((item, index) => {
          const userCount = item?.users?.length;
          return (
            <RowList
              key={`${item?.id}_${index}`}
              onClick={() => {
                openCompanyDetail(item);
              }}>
              <RowItem>
                <AvtarBlog>
                  <Avtar imgSrc={item?.logoUrl} name={item?.name} size={42} />
                </AvtarBlog>
                <WorkUser>
                  <WorkPlaceBlog>
                    <NameTitle>{item?.name}</NameTitle>
                    {item?.isAdmin && <Admin>Admin</Admin>}
                  </WorkPlaceBlog>
                  <Plan>{item?.plan}</Plan>
                </WorkUser>
              </RowItem>
              {userCount > 0 && <Users>{userCount} users</Users>}
            </RowList>
          );
        })}
      </WorkplaceWrapper>
    </MainContact>
  );
};

export default UserDetailModal;
