import React, { useCallback, useState, useEffect, useRef } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { useSelector } from 'react-redux';
// import { useHistory } from 'react-router-dom';
import { debounce } from '@material-ui/core';
import { getGravatarURL } from '../../helpers/common';
import Avtar from '../Avtar/Avtar';
import Icon from '../Icon/Icon';
import PlatformText from '../PlatformText/PlatformText';
import {
  InputWrapper,
  Input,
  InputBox,
  SearchIcon,
  SearchWrapper,
  SearchBox,
  // Span,
  Ul,
  Li,
  UserName,
  UserMail,
  UserWrapper,
  AvtarWrapper,
  UserNameWrapper,
  UserMailWrapper,
  Overlay
} from './styles';

// let timeout;
// let elSelectedRow;

// const UP_ARROW = 38;
// const DOWN_ARROW = 40;
// const ENTER = 13;

const TitleSearchBar = () => {
  const authSelector = useSelector((state) => state.auth);
  const authUser = authSelector?.currentUser;

  const [searchText, setSearchText] = useState('');
  const [opened, setOpened] = useState(false);
  const [list, setList] = useState([]);
  const [selectionIndex, setSelectionIndex] = useState();
  // const history = useHistory();
  let elSearchInput = useRef();

  // const maxIndex = useMemo(() => {
  //   return list?.length || 0;
  // }, [list]);

  useEffect(() => {
    setSelectionIndex(0);
  }, [list]);

  const onClose = useCallback(() => {
    setOpened(false);
    setSearchText('');
    setList([]);
  }, [setSearchText]);

  const onLookupSelected = useCallback(
    (item) => {
      onClose();
      //TODO: navigate to search selected item
    },
    [onClose]
  );

  // const scrollListIfRequired = useCallback(() => {
  //   const focusedNode = elSelectedRow;
  //   if (focusedNode) {
  //     focusedNode.scrollIntoView({ block: 'end' });
  //   }
  // }, []);

  // const onNavigateSelection = useCallback(
  //   (down = true, index) => {
  //     if (index >= 0 && index < list.length) {
  //       setSelectionIndex(index);
  //       scrollListIfRequired();
  //     }
  //   },
  //   [list, scrollListIfRequired]
  // );

  // const onSelectItem = useCallback(() => {
  //   if (list?.length > 0 && selectionIndex <= list?.length) {
  //     onLookupSelected(list[selectionIndex]);
  //   }
  // }, [onLookupSelected, list, selectionIndex]);

  // const onKeyDownListener = useCallback(
  //   (event) => {
  //     try {
  //       if (timeout) clearTimeout(timeout);
  //       switch (event.keyCode) {
  //         case UP_ARROW:
  //         case DOWN_ARROW: {
  //           event.preventDefault();
  //           break;
  //         }
  //         default:
  //           break;
  //       }

  //       timeout = setTimeout(() => {
  //         if (list.length > 0) {
  //           switch (event.keyCode) {
  //             case UP_ARROW:
  //               if (selectionIndex && selectionIndex > 0) {
  //                 const newIndex = selectionIndex - 1;
  //                 onNavigateSelection(false, newIndex);
  //               } else {
  //                 onNavigateSelection(false, 0);
  //               }
  //               break;
  //             case DOWN_ARROW:
  //               if (selectionIndex < maxIndex) {
  //                 const newIndex = selectionIndex + 1;
  //                 onNavigateSelection(true, newIndex);
  //               } else if (!selectionIndex || selectionIndex === -1) {
  //                 onNavigateSelection(true, 0);
  //               }
  //               break;
  //             case ENTER:
  //               onSelectItem(selectionIndex);
  //               break;
  //             default:
  //               break;
  //           }
  //         }
  //       }, 0);
  //     } catch (e) {
  //       console.error('ERROR - onItemSelected', e);
  //     }
  //   },
  //   [list?.length, maxIndex, onNavigateSelection, onSelectItem, selectionIndex]
  // );

  // const attachListeners = useCallback(() => {
  //   window.removeEventListener('keydown', onKeyDownListener, false);
  //   window.addEventListener('keydown', onKeyDownListener, false);
  // }, [onKeyDownListener]);

  // const removeListeners = useCallback(() => {
  //   window.removeEventListener('keydown', onKeyDownListener, false);
  // }, [onKeyDownListener]);

  // useEffect(() => {
  //   if (opened) {
  //     attachListeners();
  //   } else {
  //     removeListeners();
  //   }
  // }, [opened, attachListeners, removeListeners]);

  const onOpenSearch = useCallback(
    (e) => {
      e.preventDefault();
      e.stopImmediatePropagation();
      e.stopPropagation();

      if (!authUser || !authUser?.id) return null;
      setOpened(true);
      elSearchInput.current.focus();
    },
    [authUser]
  );

  const setSearchResult = (query) => {
    //TODO: search data here
    const result = [];
    setList(result);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceSetSearchData = useCallback(debounce(setSearchResult, 300), []);

  const onCloseSearchMenu = useCallback(() => {
    if (searchText) {
      setSearchText('');
      setList([]);
    } else setOpened(false);
  }, [searchText]);

  const hotKeyOptions = {
    enableOnTags: ['INPUT', 'TEXTAREA', 'SELECT']
  };
  useHotkeys('ctrl+k,cmd+k', onOpenSearch, hotKeyOptions);
  useHotkeys(
    'esc',
    () => {
      //if open, clear search text if present else close search modal
      if (opened) {
        onCloseSearchMenu();
      }
    },
    hotKeyOptions
  );

  const onSeachQueryChange = useCallback(
    (e) => {
      const value = e.target.value;
      setSearchText(value);
      debounceSetSearchData(value);
      if (!opened && value) {
        setOpened(true);
      }
    },
    [debounceSetSearchData, opened]
  );

  const getGravatar = useCallback((item) => {
    let gravatar;
    if (item?.email) {
      gravatar = getGravatarURL(item?.email);
    }
    return gravatar;
  }, []);

  return (
    <SearchWrapper>
      <InputWrapper>
        <SearchIcon>
          <Icon fontSize='14' name='feather-search' color='var(--gray-color)' />
        </SearchIcon>
        <Input
          placeholder='Search + Action'
          value={searchText}
          autoFocus
          onChange={onSeachQueryChange}
          ref={elSearchInput}
        />
        <InputBox>
          <PlatformText mac={'⌘+K'} win={'ctrl+K'} defaultText={'ctrl+K'} />
        </InputBox>
      </InputWrapper>
      {opened && list?.length > 0 && (
        <>
          <SearchBox>
            {/* <Span>Contacts</Span> */}
            <Ul>
              {list?.map((item, index) => {
                const gravatar = getGravatar(item);
                const selected = index === selectionIndex;
                return (
                  <Li
                    key={`search_item_${item?.id}_${index}`}
                    selected={selected}
                    onClick={() => {
                      onLookupSelected(item);
                    }}
                    ref={(row) => {
                      // if (selected) elSelectedRow = row;
                    }}>
                    <AvtarWrapper>
                      <Avtar imgSrc={gravatar} name={item?.name} size={20} />
                    </AvtarWrapper>
                    <UserWrapper>
                      <UserNameWrapper>
                        <UserName>{item?.name}</UserName>
                      </UserNameWrapper>
                      <UserMailWrapper>
                        <UserMail>{item?.email}</UserMail>
                      </UserMailWrapper>
                    </UserWrapper>
                  </Li>
                );
              })}
              {/* <Li>
              <Icon fontSize='20' name='icon-user' color='var(--white-color)' />
              <UserName>Create new contact ...</UserName>
            </Li> */}
            </Ul>
          </SearchBox>
          <Overlay onClick={onCloseSearchMenu}></Overlay>
        </>
      )}
    </SearchWrapper>
  );
};

export default TitleSearchBar;
