import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { MenuItem as MaterialMenuItem } from '@material-ui/core';
import Icon from './../Icon/Icon';

const StyledMenuItem = withStyles((theme) => ({
  root: {
    '&:hover': {
      background: ' var(--popup-color-hover)',
      borderRadius: '5px;'
    },
    fontFamily: 'Inter;',
    fontStyle: 'normal;',
    fontWeight: '500;',
    fontSize: '14px;',
    lineHeight: '18px;',
    letterSpacing: '0.2px;',
    color: 'var(--dark-color);',
    margin: '0 6px',
    padding: '7px 10px'
  }
}))(MaterialMenuItem);

const MenuItem = React.forwardRef(({ iconName, text, ...props }, ref) => {
  return (
    <>
      <StyledMenuItem {...props} ref={ref}>
        {iconName && <Icon isIconButton name={iconName} fontSize='18' color='var(--gray-icon-color)' />}
        {text}
      </StyledMenuItem>
    </>
  );
});

export default MenuItem;

MenuItem.propTypes = {
  iconName: PropTypes.string,
  text: PropTypes.string
};

MenuItem.defaultProps = {
  iconName: '',
  text: 'Item Name'
};
