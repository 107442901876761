import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { SUBSCRIPTION_PLAN_STATUS } from '../../global/constants';
import { Header4, Text1 } from '../../styles';

const CellWrapper = styled.div`
  display: flex;
  align-items: center;
  I {
    cursor: pointer;
  }
`;
const Name = styled.span`
  display: block;

  ${Text1}
`;

const CellLinkWrapper = styled(NavLink)`
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: var(--primary-color);
  text-decoration: none;
  :hover {
    text-decoration: underline;
  }
`;
const MainContact = styled.div`
  background: var(--white-color);
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.24);
  width: 520px;
  height: 100%;
  margin-left: auto;
  position: relative;
  overflow: hidden;
`;
const TitleView = styled.div`
  position: absolute;
  top: 12px;
  right: 12px;
  i {
    cursor: pointer;
  }
`;
const UserWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 24px;
`;
const UsetDetail = styled.div``;

const RowList = styled.div`
  background: var(--white-color);
  border: 1px solid var(--gray-border-color);
  box-shadow: 0px 1px 3px var(--inbox-hover-color);
  border-radius: 8px;
  padding: 12px 16px 12px 12px;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  cursor: pointer;
  overflow: hidden;
`;
const WorkplaceWrapper = styled.div`
  padding: 32px;
  overflow: auto;
  height: calc(100% - 150px);
`;
const Email = styled.span`
  display: inline-block;
  color: var(--gray-color);
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
`;
const Phone = styled.span`
  display: inline-block;
  color: var(--gray-color);
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  position: relative;
  padding-left: 14px;
  margin-left: 8px;
  ::before {
    position: absolute;
    content: '';
    background-color: #d3d5dd;
    width: 6px;
    height: 6px;
    border-radius: 100%;
    top: 7px;
    left: 0;
  }
`;
const WrapperWork = styled.div`
  margin-left: 16px;
`;
const More = styled.span`
  display: inline-block;
  color: #3c7ce8;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
`;
const UsersWrapper = styled.div`
  margin-bottom: 32px;
`;
const Title = styled.span`
  display: inline-block;
  ${Header4}
  margin-bottom: 16px;
`;
const SubscriptionBlog = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
  i {
    cursor: pointer;
  }
`;
const SubTitle = styled.span`
  display: inline-block;
  color: var(--dark-color);
  ${Header4}
`;
const DetailWrapper = styled.div`
  display: flex;
  align-items: center;
  overflow: hidden;
`;
const Amount = styled.span`
  flex: 0 0 auto;
  display: inline-block;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: var(--dark-color);
  padding-left: 10px;
`;
const PlanName = styled.span`
  display: inline-block;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: var(--dark-color);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const Plan = styled.span`
  display: inline-block;
  color: var(--dark-color);
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  position: relative;
  margin-left: 6px;
  padding-left: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  ::before {
    position: absolute;
    content: '';
    background-color: #d3d5dd;
    width: 6px;
    height: 6px;
    border-radius: 100%;
    top: 8px;
    left: 0;
  }
`;
const Status = styled.span`
  display: inline-block;
  padding: 1px 4px;
  border-radius: 4px;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  margin: 0 6px;
  ${(props) =>
    props.status === SUBSCRIPTION_PLAN_STATUS.ACTIVE
      ? css`
          color: #15be4b;
          background-color: #d3f4de;
        `
      : props.status === SUBSCRIPTION_PLAN_STATUS.UNPAID
      ? css`
          color: #2e69ce;
          background-color: #e7f0ff;
        `
      : props.status === SUBSCRIPTION_PLAN_STATUS.CANCELED
      ? css`
          color: #de9e21;
          background-color: #fff2d9;
        `
      : css`
          color: #fc5757;
          background-color: #ffeaea;
        `}
`;
const Blog = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
`;
const BlogDetails = styled.div``;
const From = styled.span`
  display: inline-block;
  color: var(--gray-color);
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
`;
const To = styled.span`
  display: inline-block;
  margin: 0 8px;
  color: var(--gray-color);
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
`;
const End = styled.span`
  display: inline-block;
  color: var(--gray-color);
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
`;
const List = styled.div`
  overflow: hidden;
`;

export {
  Name,
  CellWrapper,
  CellLinkWrapper,
  MainContact,
  TitleView,
  UserWrapper,
  UsetDetail,
  RowList,
  WorkplaceWrapper,
  Email,
  Phone,
  WrapperWork,
  More,
  UsersWrapper,
  Title,
  SubscriptionBlog,
  SubTitle,
  DetailWrapper,
  Amount,
  PlanName,
  Plan,
  Status,
  Blog,
  BlogDetails,
  From,
  To,
  End,
  List
};
