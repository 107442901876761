import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ROUTE } from '../../global/routeConst';
import { logout } from '../../services/authServices';

const AccessDenied = () => {
  const dispatch = useDispatch();
  let history = useHistory();
  return (
    <div>
      [403] Access denied{' '}
      <div
        onClick={() => {
          dispatch(logout());
          history.push(ROUTE.LOGIN);
        }}>
        Logout
      </div>
    </div>
  );
};

export default AccessDenied;
