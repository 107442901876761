import { createAction } from '@reduxjs/toolkit';
import * as Actions from './types';

/**
 * @desc Set Company Loader
 */
export const setCompanyLoader = createAction(Actions.SET_COMPANY_LOADER);
/**
 * @desc Set User Company List
 */
export const setCompanyList = createAction(Actions.SET_COMPANY_LIST);
/**
 * @desc Set User Companies
 */
export const setUserCompanies = createAction(Actions.SET_USER_COMPANIES);
/**
 * @desc Set Company Item
 */
export const setCompanyItem = createAction(Actions.SET_COMPANY_ITEM);
