import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { login } from '../../../services/authServices';
import Input from '../../../components/Input';
import Button from '../../../components/Button';
import Message from '../../../components/Message';
import AppLogo from '../../../components/AppLogo';
import { setAuthValidationError } from '../../../actions/authActions';
import { ROUTE } from '../../../global/routeConst';
import { ManeWrapper, LoginContainer, Title, LogoWrapper, Form, ButtonWrapper } from './styles';

const Login = ({ ...props }) => {
  const auth = useSelector((state) => state.auth);
  const { loading, validationError } = auth;

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const dispatch = useDispatch();

  useEffect(() => {
    // componentDidMount events
    return () => {
      // componentWillUnmount events
      dispatch(setAuthValidationError(''));
    };
  }, [dispatch]);

  const onSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      const loginObj = {
        username: email,
        password: password,
        appName: 'AdminWebApp'
      };

      const result = await dispatch(login(loginObj));
      console.log('result', result);
      if (result) {
        props.history.push(ROUTE.MAIN);
      }
    },
    [dispatch, email, password, props.history]
  );

  const renderErrors = () => {
    return <Message text={validationError} isSuccess={false} />;
  };

  return (
    <ManeWrapper>
      <LoginContainer>
        <LogoWrapper>
          <AppLogo />
          <Title>Log In</Title>
        </LogoWrapper>
        <Form onSubmit={onSubmit}>
          <Input
            isPrimary={true}
            type='email'
            maxLength='256'
            autocomplete='off'
            required=''
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            id='email'
            name='email'
            placeholder='Email'
          />
          <Input
            isPrimary={true}
            type='password'
            maxLength='256'
            required=''
            autocomplete='off'
            id='password'
            name='Password'
            placeholder='Password'
            value={password}
            onChange={(e) => {
              setPassword(e.target.value);
            }}
          />
          {renderErrors()}
          <ButtonWrapper>
            <Button size='large' title='Login' type='submit' loading={loading} />
          </ButtonWrapper>
        </Form>
      </LoginContainer>
    </ManeWrapper>
  );
};

export default Login;
