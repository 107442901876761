import React from 'react';
import jwt_decode from 'jwt-decode';
import { Route, Redirect } from 'react-router-dom';
import { ROUTE } from '../global/routeConst';
import { setupToken } from '../helpers/authTokenHelpers';

const AdminPrivateRoute = ({ component: Component, auth, roleModuleName, role, ...rest }) => {
  const token = setupToken();
  let hasAccess = false;
  if (token) {
    const decoded = jwt_decode(token);
    const loginRole = decoded['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'];
    if (loginRole === 'admin' || loginRole === 'sales') hasAccess = true;
  }
  return (
    <Route
      {...rest}
      render={(props) => {
        if (token) {
          if (hasAccess) {
            return <Component {...props} />;
          }
          return <Redirect to={ROUTE.ACCESS_DENIED} />;
        }
        return <Redirect to={ROUTE.LOGIN} />;
      }}
    />
  );
};

export default AdminPrivateRoute;
