import styled, { css } from 'styled-components';
import Button from '../Button';

const NavbarLeft = styled.div`
  display: flex;
  align-items: center;
  -webkit-app-region: no-drag;
  ${(props) =>
    props.mac &&
    css`
      padding-left: 87px;
    `}
`;
const NavbarTopNavigation = styled.div`
  display: flex;
  align-self: center;
  justify-content: end;
`;
const ArrowNavigation = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
`;
const BackButton = styled(Button)`
  padding: 0px;
  box-shadow: none;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  color: var(--gray-more-icon);
  margin-right: 2px;
  :hover {
    background-color: var(--gainsBoro-color);
  }
  i {
    margin: 0;
    font-size: 24px;
    line-height: 24px;
  }
  ${(props) =>
    props.disabled &&
    css`
      color: var(--gray-more-icon);
      :hover {
        background-color: transparent;
      }
    `}
`;
const ButtonLeft = styled.button`
  padding: 4px;
  box-shadow: none;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  color: var(--gray-more-icon);
  margin-right: 2px;
  border: none;
  outline: none;
  cursor: pointer;
  :hover {
    background-color: var(--Gunmetal);
  }
  i {
    margin: 0;
  }
  ${(props) =>
    props.disabled &&
    css`
      color: var(--gray-more-icon);
      :hover {
        background-color: transparent;
      }
    `}
  ${(props) =>
    props.right &&
    css`
      margin-left: 2px;
      margin-right: 0;
    `}
    ${(props) =>
    props.left &&
    css`
      margin-left: 2px;
    `}
`;

const ForwordButton = styled(Button)`
  padding: 0px;
  box-shadow: none;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  color: var(--gray-more-icon);
  margin-left: 2px;
  :hover {
    background-color: var(--gainsBoro-color);
  }
  i {
    margin: 0;
    font-size: 24px;
    line-height: 24px;
  }
  ${(props) =>
    props.disabled &&
    css`
      color: var(--gray-more-icon);
      :hover {
        background-color: transparent;
      }
    `}
`;
const ManeWrapper = styled.div`
  background: var(--cultured-color);
  border-bottom: 1px solid var(--gray-border-color);
  padding: 6px 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  -webkit-user-select: auto;
  -webkit-app-region: drag;
  position: relative;
  z-index: 9;
`;
const NavbarRightWrapper = styled.div`
  display: flex;
  align-items: center;
  -webkit-app-region: no-drag;
  ${(props) => props.win && css``}
`;
const NavbarRight = styled.div`
  margin-left: 6px;
  width: 24px;
  height: 24px;
  padding: 4px;
  border-radius: 4px;
  cursor: pointer;
  display: inline-flex;
  transition: all 300ms;
  :hover {
    background-color: var(--gainsboro-color);
    i {
      color: var(--gray-icon-color);
    }
  }
  :first-child {
    margin-left: 0;
  }
  :last-child {
    padding: 0;
  }
`;
const NavbarButtonWrapper = styled.div`
  -webkit-app-region: no-drag;
  display: flex;
  align-items: center;
  padding-left: 20px;
`;

export {
  NavbarLeft,
  NavbarTopNavigation,
  ArrowNavigation,
  ForwordButton,
  BackButton,
  ManeWrapper,
  NavbarRight,
  NavbarRightWrapper,
  NavbarButtonWrapper,
  ButtonLeft
};
