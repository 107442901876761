import React from 'react';
import classNames from 'classnames';
import { ROUTE } from '../../global/routeConst';
import { MainNav, NavList, Ul, Li, SpanList, ListView, NavIcon, MenuItemLink, MainNavWrapper } from './styles';
const Navbar = (props) => {
  const { navbarNavigation, sideBarValue } = props;
  return (
    <MainNav className={sideBarValue === 1 && navbarNavigation && 'sidebarWrapper'}>
      {sideBarValue === 1 && (
        <>
          <>
            <MainNavWrapper className={navbarNavigation && 'sidebarBlock'}>
              <NavList className={classNames({ sidebarNav: navbarNavigation })}>
                <ListView>
                  <Ul className={navbarNavigation && 'ul'}>
                    <Li className={navbarNavigation && 'hideSpan'}>
                      <MenuItemLink to={ROUTE.HOME} activeClassName='active_menu'>
                        <NavIcon name='icon-dashboard' />
                        <SpanList>Dashboard</SpanList>
                      </MenuItemLink>
                    </Li>
                    <Li className={navbarNavigation && 'hideSpan'}>
                      <MenuItemLink to={ROUTE.USERS} activeClassName='active_menu'>
                        <NavIcon name='icon-user' />
                        <SpanList>Users</SpanList>
                      </MenuItemLink>
                    </Li>
                    <Li className={navbarNavigation && 'hideSpan'}>
                      <MenuItemLink to={ROUTE.WORKSPACES} activeClassName='active_menu'>
                        <NavIcon name='icon-account' />
                        <SpanList>Workspaces</SpanList>
                      </MenuItemLink>
                    </Li>
                    {/* <Li className={navbarNavigation && 'hideSpan'}>
                      <MenuItemLink to={ROUTE.SUBSCRIPTIONS} activeClassName='active_menu'>
                        <NavIcon name='icon-task' />
                        <SpanList>Subscriptions</SpanList>
                        <IconPluse>
                          <Icon name='icon-pluse' fontSize='16' color='var(--gray-icon-color)' />
                        </IconPluse>
                      </MenuItemLink>
                    </Li> */}
                  </Ul>
                </ListView>
              </NavList>
            </MainNavWrapper>
          </>
        </>
      )}
    </MainNav>
  );
};
export default Navbar;
