import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { Header4, Text1 } from '../../styles';

const Left = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
const CellWrapper = styled.div`
  display: flex;
  align-items: center;
  .input {
    margin: 0;
    width: 14px;
    height: 14px;
  }
`;
const Name = styled.span`
  display: block;

  ${Text1}
`;

const CellLinkWrapper = styled(NavLink)`
  padding-left: 12px;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: var(--primary-color);
  text-decoration: none;
  :hover {
    text-decoration: underline;
  }
`;
const UserWrapper = styled.div``;
const UsetDetail = styled.div``;

const RowList = styled.div`
  background: var(--white-color);
  border: 1px solid var(--gray-border-color);
  box-shadow: 0px 1px 2px var(--pagination-box-shadow);
  border-radius: 6px;
  padding: 10px;
  margin: 0px auto 16px;
  &:hover {
    box-shadow: 0px 1px 3px var(--inbox-hover-color);
  }
`;
const MainContact = styled.div`
  outline: none;
  background-color: var(--white-color);
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.24);
  width: 520px;
  height: 100%;
  margin-left: auto;
  position: relative;
  overflow: hidden;
`;
const TitleText = styled.span`
  ${Header4};
  color: var(--dark-color);
  margin-left: 12px;
`;
const TitleView = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 16px;
  border-bottom: 1px solid var(--Platinum-color);
  i {
    cursor: pointer;
  }
`;
const BottomView = styled.div`
  margin-top: auto;
  border-top: 1px solid var(--Platinum-color);
  padding: 16px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  background: var(--white-color);
`;
const Form = styled.form`
  padding: 16px;
  height: calc(100% - 116px);
  overflow: auto;
`;

const LeftWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: auto;
  width: 157px;
  justify-content: space-between;
`;
const FormWrapper = styled.div``;
const ListWrappper = styled.div`
  width: 100%;
  margin-bottom: 16px;
`;
const DropDownTitle = styled.span`
  ${Text1};
  color: var(--dark-color);
  margin-bottom: 4px;
  display: block;
`;
const marginBottom = css`
  margin-bottom: 16px;
`;
const TextArea = styled.textarea`
  border: 1px solid var(--gray-border-color);
  border-radius: 4px;
  width: 100%;
  resize: none;
  outline: none;
  padding: 7px 12px;
  font-family: 'Inter';
  &:focus {
    margin: 0 -1px;
    border: 2px solid var(--primary-color);
    padding: 6px 12px;
  }
`;

const DateTimeWrapper = styled.div`
  width: 100%;
  background-color: var(--white-color);
  .DatePickerWrapper {
    width: 100%;
    .MuiInputBase-input {
      &:focus {
        border: 2px solid var(--primary-color);
      }
    }
  }
  .TimePickerWrapper {
    width: 100%;
  }
`;

const ButtonWrapper = styled.div`
  margin-right: 12px;
`;

const InputWrapperModal = styled.div`
  display: block;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-bottom: 12px;
  ${(props) =>
    props.paddingLeftRemover &&
    css`
      padding-left: 0;
    `}
  input {
    z-index: 1;
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 14px;
    width: 14px;
    left: 0px;
    padding-top: 10px;
  }
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 14px;
    width: 14px;
    background-color: var(--white-color);
    border: 1px solid var(--gainsboro-color);
    border-radius: 4px;
  }
  :hover input ~ .checkmark {
    border: 1px solid var(--spanish-gray);
  }
  input:checked ~ .checkmark {
    background-color: var(--primary-color);
    border: 1px solid var(--primary-color);
  }
  input:checked ~ .checkmark:after {
    display: block;
  }
  .checkmark:after {
    content: '';
    position: absolute;
    display: none;
    left: 4px;
    top: 1px;
    width: 4px;
    height: 8px;
    border: solid var(--white-color);
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`;
const LabelInput = styled.label`
  display: inline;
  position: relative;
  padding-left: 26px;
  padding-bottom: 2px;
  cursor: pointer;
  ${Text1}
  text-transform: capitalize;
  color: var(--dark-color);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;
const Requierd = styled.span`
  color: #ec1b17;
  ${Text1};
  display: inline-block;
  margin-left: 2px;
`;

export {
  Left,
  DateTimeWrapper,
  ButtonWrapper,
  InputWrapperModal,
  LabelInput,
  Name,
  CellWrapper,
  CellLinkWrapper,
  MainContact,
  TitleView,
  UserWrapper,
  UsetDetail,
  RowList,
  TitleText,
  Form,
  BottomView,
  TextArea,
  marginBottom,
  DropDownTitle,
  ListWrappper,
  FormWrapper,
  LeftWrapper,
  Requierd
};
