import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { Header2, Header4, Text1, Text4 } from '../../styles';

const CellWrapper = styled.div`
  display: flex;
  align-items: center;
  .input {
    margin: 0;
    width: 14px;
    height: 14px;
  }
  I {
    cursor: pointer;
  }
`;
const Name = styled.span`
  display: block;
  ${Text1}
`;

const CellLinkWrapper = styled(NavLink)`
  padding-left: 12px;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: var(--primary-color);
  text-decoration: none;
  :hover {
    text-decoration: underline;
  }
`;
const MainContact = styled.div`
  background-color: var(--white-color);
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.24);
  width: 520px;
  height: 100%;
  margin-left: auto;
  position: relative;
  overflow: hidden;
`;
const TitleView = styled.div`
  position: absolute;
  top: 12px;
  right: 12px;
  i {
    cursor: pointer;
  }
`;
const UserWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 24px;
`;
const UserBlog = styled.div`
  padding-left: 16px;
  flex: 1 1 100%;
  overflow: hidden;
`;
const RowList = styled.div`
  background: var(--white-color);
  border: 1px solid var(--gray-border-color);
  box-shadow: 0px 1px 3px var(--inbox-hover-color);
  border-radius: 8px;
  padding: 12px 16px 12px 12px;
  margin-top: 16px;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
`;
const RowItem = styled.div`
  display: flex;
  overflow: hidden;
`;
const UserDetails = styled.div`
  margin-bottom: 10px;
  display: flex;
  align-items: center;
`;
const UserDetailsWrapper = styled.div`
  display: flex;
  align-items: center;
  overflow: hidden;
`;
const TopBar = styled.div`
  background-color: var(--cultured-color);
  padding: 24px 32px;
`;
const AvtarWrapper = styled.div`
  flex: 1 1 60px;
  width: 60px;
  height: 60px;
  border: 1px solid var(--white-color);
  border-radius: 100%;
`;
const UserName = styled.span`
  ${Header2}
  color: var(--dark-color);
  margin-right: 12px;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
const UserLocation = styled.span`
  position: relative;
  color: var(--gray-color);
  ${Text4}
  padding-left: 18px;
  display: inline-block;
  ::before {
    position: absolute;
    content: '';
    background-color: var(--gainsBoro-color);
    width: 6px;
    height: 6px;
    border-radius: 100%;
    top: 50%;
    left: 0;
    transform: translate(0px, -50%);
  }
`;
const UserDetailsPhone = styled.span`
  margin-left: 8px;
  color: var(--dark-color);
  ${Text1}
  display: inline-block;
  flex: 1 1 0%;
`;
const UserDetailsEmail = styled.span`
  flex: 1 1 0%;
  margin-left: 8px;
  color: var(--dark-color);
  ${Text1}
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
const UserPhoneDetails = styled.div`
  display: flex;
  align-items: center;
  margin-right: 12px;
  i {
    flex: 1 1 16px;
  }
`;
const Ul = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`;
const Li = styled.div`
  width: 100%;
  max-width: 50%;
  position: relative;
  padding-left: 16px;
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  ::before {
    position: absolute;
    content: '';
    background-color: var(--dark-color);
    width: 6px;
    height: 6px;
    border-radius: 100%;
    top: 6px;
    left: 0;
  }
  :last-child {
    margin-bottom: 0;
  }
  :nth-last-child(2) {
    margin-bottom: 0;
  }
`;
const Label = styled.span`
  color: var(--gray-color);
  ${Text1}
  margin-right:10px;
  display: inline-block;
`;
const DaysLabel = styled.span`
  ${Text1}
  color:var(--dark-color);
  display: inline-block;
`;
const WorkplaceWrapper = styled.div`
  padding: 32px;
  overflow: auto;
  height: calc(100% - 183px);
`;
const Title = styled.span`
  display: inline-block;
  ${Header4}
`;
const WorkUser = styled.div`
  margin: 0 16px;
  overflow: hidden;
`;
const NameTitle = styled.span`
  ${Header4}
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: var(--dark-color);
`;
const Admin = styled.span`
  display: inline-block;
  margin-left: 6px;
  padding: 1px 4px;
  background-color: var(--Cultured-color);
  border-radius: 4px;
  color: var(--gray-color);
  line-height: 16px;
  font-size: 12px;
  font-weight: 500;
`;
const Plan = styled.span`
  display: inline-block;
  color: var(--gray-color);
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
`;
const Users = styled.span`
  display: inline-block;
  color: var(--gray-color);
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  flex: 0 0 auto;
  margin-left: auto;
`;
const WorkPlaceBlog = styled.div`
  display: flex;
  align-items: center;
`;
const AvtarBlog = styled.div`
  width: 42px;
  height: 42px;
`;

export {
  Name,
  CellWrapper,
  CellLinkWrapper,
  MainContact,
  TitleView,
  UserWrapper,
  UserBlog,
  RowList,
  RowItem,
  UserDetails,
  UserDetailsWrapper,
  TopBar,
  AvtarWrapper,
  UserName,
  UserLocation,
  UserDetailsPhone,
  UserPhoneDetails,
  Ul,
  Li,
  Label,
  DaysLabel,
  UserDetailsEmail,
  WorkplaceWrapper,
  Title,
  WorkUser,
  NameTitle,
  Admin,
  Plan,
  Users,
  WorkPlaceBlog,
  AvtarBlog
};
