import React, { useState, useEffect, useMemo } from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import { Helmet } from 'react-helmet';
import history from './history';
import routes from './routes/routes-without-navbar';
import AppLoading from './components/AppLoading';
import store from './store/store';
import { appInit } from './services/appService';
import SnackBar from './components/SnackBar/SnackBar';
import PrivateRoutes from './components/PrivateRoutes';
import PageNotFound from './pages/PageNotFound';

const App = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function init() {
      try {
        await store.dispatch(appInit());
      } catch (e) {
        console.log('ERROR', e);
      } finally {
        setLoading(false);
      }
    }

    init();
  }, []);

  const header = useMemo(() => {
    return (
      <Helmet>
        <title>Salescamp</title>
        <link rel='icon' type='image/png' href='/logo192.png' />
        <link rel='apple-touch-icon' href='/logo192png' />
        <link rel='manifest' href='/manifest.json' />
      </Helmet>
    );
  }, []);

  if (loading) {
    return <AppLoading />;
  }

  const routeComponents = routes.map((r, i) => {
    if (r.private) {
      return <PrivateRoutes key={i} {...r} />;
    } else {
      return <Route key={i} {...r} />;
    }
  });
  return (
    <>
      {header}
      <Provider store={store}>
        <Router history={history}>
          <Switch>
            {routeComponents}
            <Route component={PageNotFound} />
          </Switch>
        </Router>
        <SnackBar />
      </Provider>
    </>
  );
};
export default App;
