import styled from 'styled-components';
import { Header1 } from '../../../styles';

const ManeWrapper = styled.div`
  background: var(--main-bg);
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;
const LoginContainer = styled.div`
  width: 100%;
  max-width: 345px;
  margin: auto;
`;
const LogoWrapper = styled.div`
  text-align: center;
  margin-bottom: 41px;
  svg {
    display: block;
    margin-bottom: 14px;
    margin-left: auto;
    margin-right: auto;
  }
`;
const Title = styled.h1`
  ${Header1}
  text-align: center;
  color: var(--dark-color);
  margin: 0;
  padding-top: 14px;
`;
const Form = styled.form`
  input {
    position: relative;
    margin-top: -1px;
  }
  #email {
    border-radius: 4px 4px 0px 0px;
    z-index: 1;
  }
  #password {
    border-radius: 0px 0px 4px 4px;
    z-index: 0;
    &:focus {
      z-index: 1;
    }
  }
  .button {
    width: 100%;
    justify-content: center;
  }
`;
const ButtonWrapper = styled.div`
  justify-content: space-between;
  display: flex;
  align-items: center;
  margin: 20px 0 30px 0;
  padding-left: 2px;
  flex-wrap: wrap;
`;
const ForgotLink = styled.a`
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.4px;
  color: var(--gray-color);
  text-decoration: none;
  :hover {
    text-decoration: underline;
  }
`;

export { ManeWrapper, LoginContainer, LogoWrapper, Title, Form, ButtonWrapper, ForgotLink };
