import styled from 'styled-components';
const ManeWrapper = styled.div`
  display: flex;
  height: 100%;
`;
const RightWrapper = styled.div`
  width: 100%;
  margin-left: 200px;
  overflow-x: hidden;
`;
const ManeBlock = styled.div`
  width: 215px;
  height: calc(100% - 43px);
  position: fixed;
  z-index: 1;
  :hover {
    .resizeDriver {
      opacity: 1;
    }
  }
  .sidebarWrapper {
    width: 60px;
  }
  &.sidebar {
    width: 60px;
  }
  .sidebarBlock {
    width: 48px;
    padding: 12px 8px;
  }
  .hide {
    display: none;
  }
  .hideSpan {
    margin: 4px 0;
    a {
      width: 32px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
      i {
        font-size: 20px;
      }
    }
    span {
      display: none;
    }
  }
  .imgWrapper {
    padding: 0 0px 30px 0px;
  }
  .sidebarNav {
    width: 48px;
  }
  .ul {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .navBottom {
    width: 45px;
    padding: 0;
    li {
      width: 34px;
      height: 34px;
      margin: auto;
      div {
        width: 34px;
        height: 34px;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
  .more {
    padding: 0;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    i {
      font-size: 20px;
    }
  }
`;
const Link = styled.div`
  background-color: var(--primary-color-secondery-active);
  border-radius: 24px;
  width: 8px;
  height: 40px;
  position: absolute;
  right: -2px;
  margin-left: 6px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  transition: 0.3s ease-in-out;
  z-index: 1;
  opacity: 0;
  cursor: pointer;
`;
export { ManeWrapper, RightWrapper, ManeBlock, Link };
