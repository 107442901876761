import styled, { css } from 'styled-components';
import { Text1, Text2 } from '../../styles';

const InputWrapper = styled.div`
  width: 100%;
  position: relative;
  max-width: 420px;
  margin: 0 auto;
  -webkit-app-region: no-drag;
`;
const Input = styled.input`
  background-color: var(--gainsBoro-color);
  border: 0;
  outline: 0;
  border-radius: 6px;
  padding: 7px 47px 7px 40px;
  width: 100%;
  color: var(--dark-color);
  ${Text2}
  ::placeholder {
    color: var(--gray-color);
  }
`;
const InputBox = styled.div`
  background-color: var(--cultured-color);
  border-radius: 4px;
  color: var(--gray-color);
  padding: 3px 4px;
  width: fit-content;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 8px;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
`;
const SearchIcon = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 12px;
`;
const SearchWrapper = styled.div`
  position: relative;
  width: 100%;
`;
const SearchBox = styled.div`
  width: 420px;
  background: var(--white-color);
  padding: 0 6px;
  border: 1px solid var(--gray-border-color);
  position: absolute;
  top: 43px;
  left: 50%;
  z-index: 99;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  transform: translateX(-50%);
  overflow: hidden;
`;
const Span = styled.span`
  ${Text2}
  color:var(--gray-color);
  display: block;
  margin: 12px 0 6px 16px;
`;
const Ul = styled.ul`
  padding: 8px 0 6px 0;
  margin: 0;
  list-style: none;
`;

const Li = styled.li`
  padding: 8px 16px;
  margin-bottom: 2px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  cursor: pointer;
  :hover {
    background-color: var(--cultured-color);
  }
  ${(props) =>
    props.selected &&
    css`
      background-color: var(--cultured-color);
    `}
`;
const UserNameWrapper = styled.div`
  flex: 1 1 auto;
`;
const UserName = styled.h5`
  margin: 0 8px 0 16px;
  color: var(--dark-color);
  ${Text1}
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const UserMailWrapper = styled.div`
  flex: 1 1 auto;
  overflow: hidden;
`;
const UserMail = styled.h5`
  margin: 0;
  color: var(--gray-icon-color);
  ${Text2}
  position: relative;
  padding-left: 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  ::before {
    content: '';
    position: absolute;
    width: 4px;
    height: 4px;
    background-color: var(--gray-color);
    border-radius: 100%;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
  }
`;
const UserWrapper = styled.div`
  display: flex;
  align-items: center;
  overflow: hidden;
`;
const AvtarWrapper = styled.div`
  width: 100%;
  max-width: 20px;
  img {
    display: block;
  }
`;
const Overlay = styled.div`
  position: fixed;
  width: 100%;
  height: calc(100% - 43px);
  top: auto;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 0;
`;

export {
  InputWrapper,
  Input,
  InputBox,
  SearchIcon,
  SearchWrapper,
  SearchBox,
  Span,
  Ul,
  Li,
  UserName,
  UserMail,
  UserWrapper,
  AvtarWrapper,
  UserNameWrapper,
  UserMailWrapper,
  Overlay
};
