import { ROUTE } from '../global/routeConst';
import AccessDenied from '../pages/AccessDenied';
import Home from '../pages/Home';
import Login from '../pages/Membership/Login';

/**
 * Routes Array
 * Same properties should match for all attributes
 */
const routes = [
  {
    path: ROUTE.LOGIN,
    exact: true,
    component: Login,
    private: false
  },
  {
    path: ROUTE.ACCESS_DENIED,
    exact: true,
    component: AccessDenied
  },
  {
    path: ROUTE.MAIN,
    component: Home,
    private: true
  }
];

export default routes;
