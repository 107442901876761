/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback, useEffect, useState } from 'react';
import {
  useTable,
  usePagination,
  useRowSelect,
  useSortBy,
  useFilters,
  useGlobalFilter,
  useAsyncDebounce
} from 'react-table';
import DropDown from '../DropDown/DropDown';
import { itemPerPage } from '../../data/raw';
import Icon from '../Icon/Icon';
import UserPreferenceSingleton from '../../helpers/userPreferenceSingleton';
import {
  Styles,
  StylesWrapper,
  PaginationWrapper,
  PaginationLeft,
  Span,
  PaginationRight,
  ArrowButton,
  Tr,
  customestyle,
  SpanPage,
  SpanArrow,
  HeaderTitle,
  ViewIcon,
  FilterWrapper,
  GlobalInput,
  TableTitle,
  ThTr,
  Th,
  InputBox
} from './Styles';

const PAGE_SIZE = 'pageSize';
const SORTED_FEILDS = 'sortedFeilds';

function GlobalFilter({ preGlobalFilteredRows, globalFilter, setGlobalFilter }) {
  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <FilterWrapper>
      <TableTitle>Company</TableTitle>
      <InputBox>
        <Icon name='icon-search' fontSize='14' color='var(--light-gray)' />
        <GlobalInput
          isPrimarySmall={true}
          value={value || ''}
          onChange={(e) => {
            setValue(e.target.value);
            onChange(e.target.value);
          }}
          placeholder='Search + Action'
        />
      </InputBox>
    </FilterWrapper>
  );
}

export default function Table({ columns, data, initialState, tabName, openMoreMenu }) {
  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    nextPage,
    previousPage,
    setPageSize,
    selectedFlatRows,
    visibleColumns,
    preGlobalFilteredRows,
    setGlobalFilter,
    state: { pageIndex, pageSize, globalFilter }
  } = useTable(
    {
      columns,
      data,
      initialState
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [...columns]);
    }
  );

  useEffect(() => {
    const pageSize = UserPreferenceSingleton.getInstance().getSettingValue(tabName, PAGE_SIZE) || 100;
    setPerPageItem(pageSize);
    setPageSize(Number(pageSize));
  }, [selectedFlatRows, setPageSize, tabName]);

  const [perPageItem, setPerPageItem] = useState(100);

  const handleChangePageSize = (value) => {
    setPageSize(Number(value));
    setPerPageItem(Number(value));
    UserPreferenceSingleton.getInstance().setSettingValue(tabName, PAGE_SIZE, value);
  };

  const onChangeSorting = useCallback(
    (column) => {
      let desc = column?.isSorted ? (column?.isSortedDesc ? true : false) : undefined;
      const value = {
        fieldId: column?.id,
        desc
      };
      UserPreferenceSingleton.getInstance().setSettingValue(tabName, SORTED_FEILDS, value);
    },
    [tabName]
  );

  return (
    <>
      <StylesWrapper>
        <Styles>
          <table {...getTableProps()}>
            <thead>
              <ThTr>
                <Th colSpan={visibleColumns.length}>
                  <GlobalFilter
                    preGlobalFilteredRows={preGlobalFilteredRows}
                    globalFilter={globalFilter}
                    setGlobalFilter={setGlobalFilter}
                  />
                </Th>
              </ThTr>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                      <HeaderTitle
                        onClick={() => {
                          setTimeout(() => {
                            onChangeSorting(column);
                          }, 10);
                        }}>
                        {column.render('Header')}
                        <SpanArrow>
                          {column?.isSorted} {column?.isSorted ? (column.isSortedDesc ? ' ↓' : ' ↑') : ''}
                        </SpanArrow>
                      </HeaderTitle>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.map((row, i) => {
                prepareRow(row);
                return (
                  <Tr {...row.getRowProps()}>
                    {row.cells.map((cell, index) => {
                      return index === 0 ? (
                        <th {...cell.getCellProps()}>{cell.render('Cell')}</th>
                      ) : (
                        <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                      );
                    })}
                    <td>
                      {openMoreMenu && (
                        <ViewIcon>
                          <Icon
                            title='More'
                            name='icon-more'
                            fontSize='24'
                            color='var(--gray-icon-color)'
                            onClick={(e) => {
                              openMoreMenu(e, row?.original?.id);
                            }}
                          />
                        </ViewIcon>
                      )}
                    </td>
                  </Tr>
                );
              })}
            </tbody>
          </table>
        </Styles>
      </StylesWrapper>
      <PaginationWrapper className='resize'>
        <PaginationLeft>
          <DropDown
            options={itemPerPage}
            styles={customestyle}
            labelField={'text'}
            valueField={'value'}
            value={perPageItem}
            menuPlacement={'top'}
            onChange={(e) => {
              handleChangePageSize(Number(e?.value));
            }}
          />
          <SpanPage>Items per page</SpanPage>
        </PaginationLeft>
        <PaginationRight>
          <Span>
            {pageIndex * pageSize + 1} - {pageIndex * pageSize + page.length} of {data?.length}
          </Span>
          <ArrowButton onClick={() => previousPage()} disabled={!canPreviousPage}>
            <Icon name='feather-chevron-left' fontSize='20' color='var(--gray-icon-color)' />
          </ArrowButton>
          <ArrowButton onClick={() => nextPage()} disabled={!canNextPage}>
            <Icon name='feather-chevron-right' fontSize='20' color='var(--gray-icon-color)' />
          </ArrowButton>
        </PaginationRight>
      </PaginationWrapper>
    </>
  );
}
